import { reactive } from 'vue'
import { io } from 'socket.io-client'
import store from '@/store'

export const state = reactive({
  connected: false
})

const URL = {
  PRODUCTION: 'http://165.232.152.232:9092/notify',
  DEVELOPMENT: 'http://104.248.221.134:9092/notify'
}
export const socket = io(URL.PRODUCTION)

socket.on('connect', () => {
  console.log('Socket Conectado')
  console.log(socket.id)
  store.dispatch('socketStore/setLocations')
})

socket.on('disconnect', () => {
  console.log('Socket Desconectado')
})

socket.on('events', (response) => {
  store.dispatch('socketStore/setSocketItem', response)
  store.dispatch('socketStore/setItemToLocationModule', response)
})
