<template>
  <div class="terminal-container">
    <el-card class="image-card-container" :body-style="{ padding: '0px'}" style="margin-bottom: 0.6em;">
      <el-row justify="center">
        <el-col :span="24" align="center" style="text-aling: center; margin-bottom: 0.4em;">
          <span style="font-size: 1.2em; font-weight: 500;">Sucursal {{ setTitle }}</span>
        </el-col>
        <el-col :span="24" align="center" style="text-aling: center;">
          <span><i v-bind:style="{ fontSize: '4em', color: iconColor }" :class="iconType"></i></span>
        </el-col>
        <el-col :span="24" align="center" style="text-aling: center; margin-bottom: 0.4em;">
          <el-row justify="center" style="margin: 0;" :gutter="20">
            <el-col :span="8" align="left">
              <el-button @click="setCurrentData('left')" :disabled="btnLeftAvailable" icon="el-icon-arrow-left" circle size="small" type="info"></el-button>
            </el-col>
            <el-col :span="8" align="middle" style="text-align: center;">
              <span>{{ getIndexOfCurrentData }} / {{ getTotalDataList }}</span>
            </el-col>
            <el-col :span="8" align="right">
              <el-button @click="setCurrentData('right')" :disabled="btnRightAvailable" icon="el-icon-arrow-right" circle size="small" type="info"></el-button>
            </el-col>
          </el-row>
        </el-col>
        <template v-if="accessEventList.length >= 1">
          <el-col :span="24" style="text-aling: center;">
            <template v-if="currentData.type.toUpperCase() == 'SUCCESS'">
              <div class="content-info">
                <span>{{ currentData.message }}</span>
              </div>
              <div class="content-websocket">
                <ul>
                  <li><span class="info-span-title">Terminal:</span> {{ currentData.bioTerminalName }}</li>
                  <template v-if="currentData.metadataKeyValue !== undefined || currentData.metadataKeyValue !== null">
                    <li v-for="(itemMetadata, index) in currentData.metadataKeyValue" :key="`listMeta${index}`">
                    <span class="info-span-title">{{ itemMetadata.keyObj }}</span>: {{ itemMetadata.valueObj }}
                    </li>
                  </template>
                </ul>
              </div>
              <div class="icon-scroll-text"><i class="el-icon-caret-bottom"></i></div>
            </template>
            <template v-if="currentData.type.toUpperCase() == 'INFO'">
              <div class="content-info">
                <span>{{ currentData.message }}</span>
              </div>
              <div class="content-websocket">
                <ul>
                  <li><span class="info-span-title">Terminal:</span> {{ currentData.bioTerminalName }}</li>
                  <template v-if="currentData.metadataKeyValue !== undefined || currentData.metadataKeyValue !== null">
                    <li v-for="(itemMetadata, index) in currentData.metadataKeyValue" :key="`listMeta${index}`">
                    <span class="info-span-title">{{ itemMetadata.keyObj }}</span>: {{ itemMetadata.valueObj }}
                    </li>
                  </template>
                </ul>
              </div>
              <div class="icon-scroll-text"><i class="el-icon-caret-bottom"></i></div>
            </template>
            <template v-if="currentData.type.toUpperCase() == 'ERROR'">
              <div class="content-info error__message">
                <span>{{ currentData.message }}</span>
              </div>
              <div class="content-websocket">
                <ul>
                  <li><span class="info-span-title">Terminal:</span> {{ currentData.bioTerminalName }}</li>
                  <template v-if="currentData.metadataKeyValue !== undefined || currentData.metadataKeyValue !== null">
                    <li v-for="(itemMetadata, index) in currentData.metadataKeyValue" :key="`listMeta${index}`">
                    <span class="info-span-title">{{ itemMetadata.keyObj }}</span>: {{ itemMetadata.valueObj }}
                    </li>
                  </template>
                </ul>
              </div>
              <div class="icon-scroll-text"><i class="el-icon-caret-bottom"></i></div>
            </template>
          </el-col>
        </template>
        <template v-else>
            <el-row justify="center">
              <el-col :span="24" align="center">
                <div class="content-info error__message">
                  <span> No Hay registros disponibles</span>
                </div>
              </el-col>
              <el-col :span="24" align="center">
                <div class="icon-empty" style="margin-top: 3.2rem; margin-bottom: 3.2rem;">
                  <span><i style="font-size: 6em; color: #969696" class="el-icon-warning-outline"></i></span>
                </div>
              </el-col>
            </el-row>
        </template>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/*
const fingerType = {
  finger: 'Derecha',
  face: 'Izquierda',
  qr: 'Codigo QR'
}
*/
const colorEvents = {
  SUCCESS: '#67C23A',
  INFO: '#409EFF',
  ERROR: '#E6A23C'
}
const iconEvents = {
  SUCCESS: 'el-icon-success',
  INFO: 'el-icon-info',
  ERROR: 'el-icon-warning'
}
export default {
  name: 'LocationMonitor',
  props: {
    setlocationTitle: {
      required: false,
      default: 'Sucursal No Encontrada',
      type: String
    },
    setlocationKey: {
      required: false,
      default: null,
      type: String
    },
    setNewData: {
      required: false,
      default: null,
      type: Object
    },
    resetData: {
      required: false,
      default: null,
      type: Boolean
    }
  },
  data () {
    return {
      newData: {},
      keyComponentLocation: '',
      currentDataIndex: 0,
      iconColor: '',
      iconType: '',
      currentData: {},
      accessEventList: []
    }
  },
  created () {
    this.loadListFromStore()
  },
  mounted () {
    this.setComponentKey()
  },
  watch: {
    setNewData: function (socketResponse) {
      console.log(socketResponse)
      if (socketResponse.bioLocationKey === this.setlocationKey) {
        // this.$store.dispatch('socketStore/setItemToLocationModule', socketResponse)
        this.saveNewDate(socketResponse)
        console.log(`Lista recibida en ${this.setlocationKey}`)
      }
    }
  },
  computed: {
    ...mapState('socketStore', ['locationsModules']),
    reactiveTest () {
      return this.setNewData
    },
    setTitle () {
      return this.setlocationTitle
    },
    getTotalDataList () {
      return this.accessEventList.length
    },
    getIndexOfCurrentData () {
      if (this.accessEventList.length > 0) {
        return this.currentDataIndex + 1
      } else {
        return '0'
      }
    },
    btnLeftAvailable () {
      if (this.currentDataIndex === 0) {
        return true
      } else {
        return false
      }
    },
    btnRightAvailable () {
      if (this.currentDataIndex < this.accessEventList.length - 1) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    loadListFromStore () {
      console.log('recovery data')
      if (this.locationsModules[`${this.setlocationKey}`] !== undefined) {
        this.accessEventList = this.locationsModules[`${this.setlocationKey}`]
      } else {
        this.accessEventList = []
      }
      this.setLastData()
    },
    saveNewDate (newData) {
      // this.accessEventList.unshift(newData)
      this.accessEventList = this.locationsModules[`${this.setlocationKey}`]
      this.setLastData()
      console.log(this.accessEventList)
    },
    setComponentKey () {
      this.keyComponentLocation = this.setlocationKey
    },
    setIconType () {
      this.iconType = iconEvents[`${this.currentData.type.toUpperCase()}`]
    },
    setIconColor () {
      this.iconColor = colorEvents[`${this.currentData.type.toUpperCase()}`]
    },
    setLastData () {
      if (this.accessEventList.length >= 1) {
        this.currentData = this.accessEventList[0]
        this.currentDataIndex = 0
        this.setIconType()
        this.setIconColor()
      }
    },
    setCurrentData (event) {
      if (event === 'left') {
        if (this.currentDataIndex !== 0) {
          this.currentDataIndex = this.currentDataIndex - 1
          this.currentData = this.accessEventList[this.currentDataIndex]
          this.setIconType()
          this.setIconColor()
        }
      }
      if (event === 'right') {
        if (this.currentDataIndex < this.accessEventList.length - 1) {
          this.currentDataIndex = this.currentDataIndex + 1
          this.currentData = this.accessEventList[this.currentDataIndex]
          this.setIconType()
          this.setIconColor()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.image-card-container{
  max-width: 80rem;
  height: 340px;
  background: rgb(242, 242, 242);
  border: 1px solid rgba(179, 179, 179, 0.343);
  border-radius: 0.8rem;
}
.content-websocket{
  height: 160px;
  overflow-y: auto;
  width: inherit;
  font-size: 0.8em;
  ul {
    white-space: normal;
    padding-left: 1.8rem;
    list-style: none;
    li {
      margin:0;
    }
  }
}
.error__message{
  color: red;
}
.content-info{
  font-weight: 500;
  text-align: center;
}
.info-span-title{
  font-weight: 500;
}
.icon-scroll-text{
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
