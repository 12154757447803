<template>
  <div class="app-container">
    <!------
    <el-button @click="cleanStore" size="mini">Limpiar Datos</el-button>
    --->
    <el-row justify="center">
      <el-col>
        <el-row :gutter="10" justify="center">
          <el-col v-for="(location, index) in locationsList" :key="`LocationMonitor-${index}`" :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
              <LocationMonitor
                :setNewData="setDataToComponent"
                :setlocationKey="location.key"
                :setlocationTitle="location.name"
                key="f4bu">
              </LocationMonitor>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </template>

<script>
import { search as getAvailableLocations } from '@/api/bioLocations.js'
import { socket } from '@/api/socketService.js'
import LocationMonitor from '@/components/LocationMonitor/LocationMonitor.vue'
export default {
  name: 'Monitoring',
  components: {
    LocationMonitor
  },
  data () {
    return {
      locationsList: [],
      num: 0
    }
  },
  mounted () {
    this.getAllLocations()
  },
  unmounted () {
    this.disconnectSocket()
  },
  computed: {
    setDataToComponent () {
      return this.$store.getters.getSocketItem
    }
  },
  methods: {
    cleanStore () {
      this.$store.dispatch('socketStore/clearSocketStore')
    },
    disconnectSocket () {
      socket.disconnect()
    },
    async getAllLocations () {
      this.loading = true
      await getAvailableLocations({ query: '' })
        .then((response) => {
          this.locationsList = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
